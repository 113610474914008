import React, { useEffect, useState } from "react";
import './App.css';
import { getViewerByIntervention } from '../../services/viewer';
import useEventListener from '../../utils/useEventListener';

function App() {
    const [viewer, setViewer] = useState({});
    const [current, setCurrent] = useState({});
    const [currentPage, setCurrentPage] = useState({});

    let encode = getFullQuery();

    function getFullQuery() {
        let res =  "id=" + new URLSearchParams(window.location.search).get('id')
            + "&code=" +new URLSearchParams(window.location.search).get('code')

        var cust = new URLSearchParams(window.location.search).get('cust');
        if (cust)
            res += "&cust=" + cust;

        var space = new URLSearchParams(window.location.search).get('space');
        if (space)
            res += "&space=" + space;

        var file = new URLSearchParams(window.location.search).get('file');
        if (file)
            res += "&file=" + file;
        return res;
    }

    const handleDocumentChangeClick = event => {
        setCurrent(event.target.getAttribute("data-index"));
    };

    const handlePageChangeClick = event => {
        setCurrentPage(parseInt(event.target.getAttribute("data-index")));
    };

    const handleLeftPageChangeClick = event => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            document.getElementById("slide_" + (currentPage - 1)).scrollIntoView()
        }
    };

    const handleRightPageChangeClick = event => {
        if (current && viewer && viewer[current] && currentPage < viewer[current].length - 1) {
            setCurrentPage(currentPage + 1);
            document.getElementById("slide_" + (currentPage + 1)).scrollIntoView(false);
        }
    };

    const handleBackClick = event => {
        setCurrentPage(0);
        setCurrent("");
    };

    const handleFullscreenClick = event => {
        var element = document.querySelector("#main-image");
        element.requestFullscreen()
            .then(function () { })
            .catch(function (error) {
                console.log(error.message);
            });
    };

    const handler = (event) => {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
            handleLeftPageChangeClick(event);
        } else if (event.key === 'ArrowRight' || event.key === 'ArrowDown' || event.code === 'Space') {
            handleRightPageChangeClick(event);
        }
    }
    useEventListener("keydown", handler);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getViewerByIntervention(encode);
            setViewer(data);

            if (data && Object.keys(data).length === 1) {
                setCurrent(Object.keys(data)[0]);
            }
        }

        setCurrent("");
        setCurrentPage(0);
        fetchData().catch(console.error);
    }, [encode]);

    if (viewer && Object.keys(viewer).length === 0) {
        return (
            <div className="App">
                <h1 className="center">Loading</h1>
            </div>
        );
    }

    if (!viewer) {
        return (
            <div className="App">
                <h1 className="center">An error occured.</h1>
            </div>
        );
    }

    var documents = Object.keys(viewer);

    if (!current) {
        return (
            <div className="App">
                <div className="viewer">
                    <h3 className="viewer-title">Choose a document</h3>
                    <div className="viewer-content">
                        <div className="document-list">
                        {documents.map((document, index) => (
                            <div className="documents-selection" key={index} data-index={document} onClick={handleDocumentChangeClick}>
                                <h4 data-index={document} onClick={handleDocumentChangeClick} >{document}</h4>
                                <img className="document-image" src={"data:image/png;base64," + viewer[document][0]} data-index={document} onClick={handleDocumentChangeClick} draggable="false" />
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>)
    }

    return (
        <div className="App">
            <div className="viewer">
                
                <h3 className="viewer-title"><span className="viewer-back" onClick={handleBackClick} title="Back">🡠</span>{current}<span className="viewer-fullscreen" onClick={handleFullscreenClick} title="Fullscreen">⤡</span></h3>
                
                <div className="viewer-content">
                    <div className="viewer-slides">
                        {viewer[current].map((image, index) => (
                            <div key={"slide_" + index} id={"slide_" + index} className={"viewer-slide " + (currentPage == index ? 'active' : "")}>
                            <span className="viewer-slide-nmb">{index + 1}</span>
                            <div className="viewer-slide-img">
                                <img data-index={index} className={"image-small " + (currentPage == index ? 'active' : "")} src={"data:image/png;base64," + image} onClick={handlePageChangeClick} draggable="false" />
                            </div>
                        </div>
                    ))}
                    </div>
                    <div className="viewer-image" >
                        <div className="viewer-image-content">
                            <img className="image-full" src={"data:image/png;base64," + viewer[current][currentPage]} id="main-image" draggable="false" />
                        </div>
                        <div className="viewer-pager">
                            <span className="viewer-arrow" title="Previous" onClick={handleLeftPageChangeClick}>🠸</span>
                            <span className="viewer-page">{currentPage+1}/{viewer[current].length}</span>
                            <span className="viewer-arrow" title="Next" onClick={handleRightPageChangeClick}>🠺</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
