import configData from "../config.json";

async function getViewerByIntervention(encoding)
{
    return await fetch(configData.REACT_APP_API_BASE_URL + "/Viewer?" + encoding)
        .then((res) => res.json())
        .catch((err) => console.error(err));
}

export { getViewerByIntervention }

// const baseUrl = process.env.REACT_APP_API_BASE_URL;

// async function getViewerByIntervention(encoding)
// {
//     return await fetch(baseUrl + "/Viewer?" + encoding)
//         .then((res) => res.json())
//         .catch((err) => console.error(err));
// }

// export { getViewerByIntervention }